.Loader{
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Spinner{
  width: 6rem;
  height: 6rem;
  display: inline-block;
  border: 5px solid rgba(65, 105, 225, 0.137);
  border-radius: 50%;
  border-top-color: #1D74FF;
  margin-bottom: 3rem;
  animation: 1s spin infinite ease-in-out;
}

@keyframes spin{
  to{
    transform: rotate(360deg);
  }
}

.Waiting{
  font-weight: bold;
}

h1{
  height: 15vh;
  font-weight: 300;
  text-align: center;
  line-height: 15vh;
  color: #1D74FF;
  margin: 0 auto;
}

h3{
  font-weight: 300;
  text-align: center;
  padding: 0;
  margin: 10px;
  min-height: 40px;
  color: #1d74ff;
}

b{
  font-weight: bold;
}

.Err{
  color: red;
  background-color: white;
  padding: 5px;
  border-radius: 20px;
  font-size: 12px;
}

.Ticker {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-x: auto;
  height: fit-content;
  min-height: 75vh;
}

.PairView{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  border-radius: 25px;
  min-width: 220px;
  margin: 7px;
  background-color: #1D74FF;
  color: white;
  transition: 0.5s;
}

p{
  word-break: break-all;
}

.InfoView{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 40px;
  text-align: center;
  border: none;
  margin: 5px;
}

.DelButton{
  height: 15px;
  width: 15px;
  border: none;
  padding: 0;
  background: url("img/del.png");
  background-size: cover;
  transition: 0.2s;
  cursor: pointer;
}

.GraphButton{
  height: 15px;
  width: 15px;
  border: none;
  padding: 0;
  background: url("img/graph.png");
  background-size: cover;
  transition: 0.2s;
  cursor: pointer;
}

.GraphModeButton{
  border: none;
  background-color: transparent;
  color: white;
  width: 50px;
  height: 50px;
}

.GraphModeButtonSelected{
  border: none;
  background-color: transparent;
  color: white;
  width: 50px;
  height: 50px;
  font-weight: bold;
}

canvas{
  margin: 25px;
}

.AddPair{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  min-height: 40px;
  width: 220px;
  padding: 0;
  margin: 10px;
  border: 2px solid #1D74FF;
  cursor: text;
}

.PairForm{
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 600px){
  .PairForm{
    height: 300px;
  }
}

.PairInput{
  text-align: center;
  border: none;
  padding-left: 20px;
  height: 35px;
  width: 155px;
  margin: 5px;
  border-radius: 0;
  background-color: transparent;
}

.AddButton{
  height: 15px;
  width: 15px;
  border: none;
  padding: 0;
  margin: 0;
  background: url("img/plus2.png");
  background-size: cover;
  transition: transform 0.2s;
  cursor: pointer;
}

/*Have to use "background-size: cover" to prevent Firefox from blurring image*/
@keyframes popUpButton {
  0%{
    background-size: cover;
    transform: scale(0);
  }
  40%{
    background-size: cover;
    transform: scale(1.2);
  }
  100%{
    background-size: cover;
    transform: scale(1);
  }
}

.PairInput:focus{
  outline: none;
  border-radius: 0;
}

.PairContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.PairList{
  margin-top: -10px;
  max-height: 200px;
  width: 150px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 2px solid #1D74FF;
  border-top: none;
  background-color: white;
}

.assButt{
  border: none;
  background-color: white;
  height: 100px;
  color: #1D74FF;
  height: 45px;
  width: 150px;
  border-bottom: 1px solid #1D74FF;
}

.assButt:hover{
  background-color: #1D74FF;
  color: white;
  font-weight: bold;
}

.assButtActive{
  border: none;
  background-color: #1D74FF;
  height: 100px;
  color: white;
  height: 45px;
  width: 150px;
  border-bottom: 1px solid #1D74FF;
  font-weight: bold;
}

.Storage{
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  border: none;
  background-color: transparent;
}

.StorageButton{
  width: 150px;
  height: 50px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #1D74FF;
}

@media only screen and (min-width: 600px) {
  .AddButton:hover{
    transform: scale(1.2);
  }

  .DelButton:hover{
    transform: scale(1.2);
  }

  .GraphButton:hover{
    transform: scale(1.2);
  }

  .StorageButton:hover{
    font-weight: bold;
  }

  .Ticker{
    flex-wrap: wrap;
    height: 75vh;
  }

  .GraphModeButton:hover{
    font-weight: bold;
  }
}

::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track{
  background-color: white;
  color: transparent;
}

::-webkit-scrollbar-thumb{
  background-color: #949494;
  border-radius: 20px;
}